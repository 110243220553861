import React from 'react';
import logoImg from '../assets/logo.png';
import {Box} from '@chakra-ui/core';

class Logo extends React.Component {
  render() {
    return (
      <>
        <Box as="img" h="80px" src={logoImg} />
      </>
    );
  }
}

export default Logo;
