import Logo from '../favicon.png';
import React from 'react';
import footerBG from '../assets/footer.jpg';
import {Box, Text} from '@chakra-ui/core';

const Footer = () => {
  return (
    <Box
      display="flex"
      flexDir="column"
      alignItems="center"
      height="45vh"
      position="relative"
      backgroundAttachment="fixed"
      backgroundRepeat="no-repeat"
      backgroundSize="cover"
      backgroundPosition="center"
      justifyContent="center"
      bgImage={`url(${footerBG})`}
      _before={{content: 'this is before'}}
    >
      <Box
        bg="rgba(0,0,0,0.7)"
        position="absolute"
        zIndex="1"
        width="100%"
        height="100%"
      />
      <Box as="img" src={Logo} w={['20vw', '20vw', '7vw', '7vw']} zIndex="2" />
      <Text
        textAlign="center"
        marginTop="2rem"
        zIndex="2"
        as="h1"
        fontSize={['1.4rem', '1.4rem', '2.5rem', '2.5rem']}
        color="#fff"
        fontWeight="bold"
      >
        100% SKATER OWNED &amp; OPERATED
      </Text>
    </Box>
  );
};

export default Footer;
