import Home from './Home';
import MainLayout from '../components/layout/MainLayout';
import React from 'react';

export default function App() {
  return (
    <MainLayout>
      <Home />
    </MainLayout>
  );
}
