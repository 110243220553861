import Logo from './Logo';
import React from 'react';
import {Box, Flex, Icon} from '@chakra-ui/core';
import {GrShop} from 'react-icons/gr';
import {useCart, useCartCount} from 'gatsby-theme-shopify-manager';

const Navigation = ({onOpen}) => {
  const cart = useCart();
  const cartCount = useCartCount();
  return (
    <>
      <Flex bg="white" w={'100%'}>
        <Flex flexGrow={1}>
          <Logo />
        </Flex>
        <Flex align={'center'}>
          {cart && (
            <Flex
              onClick={onOpen}
              flexDirection="row"
              cursor="pointer"
              marginRight="1em"
            >
              <Box
                fontSize="12px"
                borderRadius="50%"
                bg="#F15F23"
                align={'left'}
                width="18px"
                height="18px"
                alignContent="center"
                justifyContent="center"
                color="#fff"
                display="flex"
                flexGrow="0"
                marginRight=".5em"
              >
                {cartCount}
              </Box>
              <Box display="flex" flexGrow="0" align={'right'} flex="1">
                <Icon as={GrShop} size="18px" />
              </Box>
            </Flex>
          )}
        </Flex>
      </Flex>
    </>
  );
};

export default Navigation;
