import React from 'react';
import headerBG from '../assets/header.jpg';
import {Box, Flex, Link, Text} from '@chakra-ui/core';

class Hero extends React.Component {
  render() {
    return (
      <>
        <Flex direction="column" h="40vh" color="white" overflow="hidden">
          <Box
            flexDir="column"
            display="flex"
            alignItems="center"
            justifyContent="center"
            bgImage={`url(${headerBG})`}
            h="100%"
            w="100%"
            bgSize="cover"
            bgPos="left center"
          >
            <Text
              as="h1"
              fontSize="3rem"
              textAlign="center"
              fontWeight="Bold"
              lineHeight="3rem"
              boxShadow="md"
            >
              CANVAS SKATEBOARDS
            </Text>
            <Text as="p" fontSize="2rem">
              {' '}
              EST 2008
            </Text>
            <Link
              marginTop="1rem"
              paddingX="1.5rem"
              borderRadius="0.25rem"
              display="inline-flex"
              height="3rem"
              alignItems="center"
              href="#shopItems"
              justifyContent="center"
              lineHeight="1.2"
              bg="#F15F23"
              color="#fff"
              _hover={{bg: '#e47a4e'}}
              _active={{bg: '#e47a4e'}}
            >
              Shop Now
            </Link>
          </Box>
        </Flex>
      </>
    );
  }
}

export default Hero;
