import Footer from '../Footer';
import Hero from '../Hero';
import Navigation from '../Navigation';
import React from 'react';
import {Box, Flex, useDisclosure} from '@chakra-ui/core';

const MainLayout = ({children}) => {
  const {isOpen, onOpen, onClose} = useDisclosure();

  return (
    <>
      <Flex position={'fixed'} w={'100%'} zIndex="22">
        <Navigation onOpen={onOpen} />
      </Flex>
      <Box pt={80}>
        <Hero />
        {React.cloneElement(children, {isOpen, onOpen, onClose})}
        <Footer />
      </Box>
    </>
  );
};

export default MainLayout;
